import React from 'react';
import Footer from '../Footer'

function EmploymentForm() {
    return (
        <>
        <h1 style={{marginTop: '100px', textAlign:'center'}}>Employment Form</h1>
    <iframe
    id="JotFormIFrame-222736573244155"
    title="Employment Form"
    onload="window.parent.scrollTo(0,0)"
    allowtransparency="true"
    allowfullscreen="true"
    allow="geolocation; microphone; camera"
    src="https://form.jotform.com/222736573244155"
    frameborder="0"
    style={{
    minWidth: '100%',
    height:'900px',
    border:'none'}}
    scrolling="no"
  >
  </iframe>
  <Footer />
  </>
    );
};

export default EmploymentForm;