import React from 'react';
import {useState,useEffect} from 'react';
import BlogCard from '../BlogCard';
import firebase from '../../Firebase'
import Footer from '../Footer';

function Blog() {
    const ref = firebase.firestore().collection('BlogPosts')
    console.log(ref)
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)

    async function getData(){
        ref.onSnapshot((querySnapshot) => {
        const items = []
        querySnapshot.forEach((doc) => {
            items.push(doc.data())
        })
        setData(items)
        setLoader(false)
        console.log(items)
        })
    }

    useEffect(() => {
        getData()
        console.log(data)
    }, [])

    return (
        <>
        <div className='heightfix'> </div>
        <center><h1 className='page-title'>Blog</h1></center>
        <div className='light-blog'>
        <div className='blog-faceted-search'>
            <h2>Blog Categories</h2>
            <ul>
            {loader === false && data.map(eachitem =>
                <li><a href={`/category/${eachitem.category.split(' ').join('-').toLowerCase()}`}>{eachitem.category}</a></li>
                )}
            </ul>
        </div>
        <div className='blog-cards'>
            {loader === false && data.map(eachitem =>
            <BlogCard author='Hoist Equipment Co., Inc.' title={`${eachitem.title}`} content={`${eachitem.content}`} id={`${eachitem.id}`} />
        )}{loader === true && <>
        <div className='blog-loading'>
            <p className='blog-loading-text'>
                Loading...
            </p>
        </div>
        </>} 
        </div>
        </div>
        <Footer />
        </>
    )
}

export default Blog