import React from 'react';
import {useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import BlogCard from '../BlogCard';
import firebase from '../../Firebase'
import Footer from '../Footer';

function Category() {
    let {category} = useParams()
    console.log(category)
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    var categoryWords = category.toString().split('-')
    var all_words = []
    for (let i=0; i<categoryWords.length;i++){
        all_words.push(capitalizeFirstLetter(categoryWords[i]))
    }
    var catString = all_words.join(' ')
    const ref = firebase.firestore().collection('BlogPosts').where('category','==',`${catString}`)
    console.log(ref)
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)

    async function getData(){
        ref.onSnapshot((querySnapshot) => {
        const items = []
        querySnapshot.forEach((doc) => {
            items.push(doc.data())
        })
        setData(items)
        setLoader(false)
        console.log(items)
        })
    }

    useEffect(() => {
        getData()
        console.log(data)
    }, [])

    return (
        <>
        <div className='heightfix'> </div>
        <center><h1 className='category-title'>{loader === false && data[0].category}</h1></center>
        <div className='breadcrumbs'>
                <p>
                    <a href='/blog'>Blog</a> &gt;&gt;&nbsp;
                    {loader === false && 
                    <a href={`/category/${data[0].category.split(' ').join('-').toLowerCase()}`}>{data[0].category}</a>
                    }
                </p>
            </div>
        <div className='light-blog-1'>
            
        <div className='blog-faceted-search'>
            <h2>Blog Categories</h2>
            <ul>
            {loader === false && data.map(eachitem =>
                <li><a href={`/category/${eachitem.category.split(' ').join('-').toLowerCase()}`}>{eachitem.category}</a></li>
                )}
            </ul>
        </div>
        <div className='blog-cards'>
            {loader === false && data.map(eachitem =>
            <BlogCard author='Hoist Equipment Co., Inc.' title={`${eachitem.title}`} content={`${eachitem.content}`} id={`${eachitem.id}`} />
        )}{loader === true && <>
        <div className='blog-loading'>
            <p className='blog-loading-text'>
                Loading...
            </p>
        </div>
        </>} 
        </div>
        </div>
        <Footer />
        </>
    )
}

export default Category