import React from 'react'
import {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import firebase from '../../Firebase'
import {Helmet} from 'react-helmet'
import Footer from '../Footer'

function BlogPost(){
    let {blogname} = useParams()
    const ref = firebase.firestore().collection('BlogPosts').where(firebase.firestore.FieldPath.documentId(),'==',`${blogname}`)
    console.log(ref)
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)

    async function getData(){
        ref.onSnapshot((querySnapshot) => {
        const items = []
        querySnapshot.forEach((doc) => {
            items.push(doc.data())
        })
        setData(items[0])
        setLoader(false)
        console.log(items)
        })
    }

    useEffect(() => {
        getData()
        console.log(data)
    }, [])

    return (
        <>
        {loader === false && (
        <Helmet>
                    <title>{data.title}</title>
                    <meta name='description' content={data.description} />
        </Helmet>
        )}
        <img style={{maxWidth: '100vw', marginTop: '-100px'}}alt={blogname} src={`../${blogname}.jpg`} />
        {loader === false && (
            <div className='blog-content' dangerouslySetInnerHTML={{__html:data.content}}></div>
        )}
        <br />
        <Footer />
        </>
    );
}

export default BlogPost;