import React from 'react';
import '../../App.css';
import Footer from '../../components/Footer'
import '../../components/Tabs.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {TabPanel, a11yProps} from '../../components/Tabs';

export default function Linecard() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return <>
    <div className="page-content">
        <h1 className="page-header">Brands We Love</h1>
        <div className='text-area'>
            <img className="virtualLineCard" alt='Virtual Line Card' src="images/logos.jpg" />
        </div>
        <br />
        <h2>Our Products & Services</h2>
    </div>
    <br />
    <hr />
    <div className='desktop-tabs'>
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 300}}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label="Conveyors" {...a11yProps(0)} />
        <Tab label="Material Handling Equipment" {...a11yProps(1)} />
        <Tab label="Cranes" {...a11yProps(2)} />
        <Tab label="Hoists" {...a11yProps(3)} />
        <Tab label="Runways" {...a11yProps(4)} />
        <Tab label="Electrification" {...a11yProps(5)} />
        <Tab label="Accessories" {...a11yProps(6)} />
        <Tab label="Technical Assistance" {...a11yProps(7)} />
        <Tab label="Controls" {...a11yProps(8)} />
        <Tab label="Parts - Service Inspection" {...a11yProps(9)} />
        <Tab label="Components" {...a11yProps(10)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ul>
            <li>Accumulating</li>
            <li>Hinged Belt</li>
            <li>Screw</li>
            <li>Belt</li>
            <li>Line Shaft</li>
            <li>Special Application</li>
            <li>Chain Type</li>
            <li>Overhead Chain</li>
            <li>Vertical Reciprocating</li>
            <li>Flex Link</li>
            <li>Roller</li>
            <li>Vibratory</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ul>
            <li>Boom Attachments</li>
            <li>Box Dumpers</li>
            <li>Cabinets</li>
            <li>Carts</li>
            <li>Dock Plates</li>
            <li>Drum Dumpers</li>
            <li>Drum Equipment</li>
            <li>Fork Lift Attachments</li>
            <li>Hand Trucks</li>
            <li>Hoppers</li>
            <li>In-Plant Offices</li>
            <li>Lift Tables</li>
            <li>Lifts</li>
            <li>Mezzanines</li>
            <li>Pallet Equipment</li>
            <li>Partitions</li>
            <li>Racks</li>
            <li>Tilt Tables</li>
            <li>Tool Balancers</li>
            <li>Trolleys</li>
            <li>Vertical Reciprocating Conveyors</li>
            <li>Work Positioners</li>
            <li>Work Station Systems</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ul>
            <li>Top Running Cranes</li>
            <li>Under Running Cranes</li>
            <li>Trambeam Crane Systems</li>
            <li>Gantry Cranes - Jibs</li>
            <li>Monorails</li>
            <li>Gorbel Work Station Cranes</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <ul>
            <li>Most Capabilities & Applications</li>
            <li>Electric Wire Rope & Chain</li>
            <li>Air Wire Rope & Chain</li>
            <li>Manual Change</li>
            <li>Built-Up Hoists</li>
            <li>Special Use Hoist</li>
            <li>Winches</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ul>
            <li>Top and Under-Running Crane Runway Systems</li>
            <li>Patented Track System</li>
            <li>ASCE Rail Systems</li>
            <li>Runway Support Structures</li>
            <li>Structural Design & Fabrication</li>
            <li>Surveys & Inspection</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={5}>
      <ul>
            <li>Runway Electrification</li>
            <li>Conductor Bar Systems</li>
            <li>Flat Cable Festooning</li>
            <li>Electric Cable Reels</li>
            <li>Collectors</li>
            <li>Parts & Accessories</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={6}>
      <ul>
            <li>Nylon Strings</li>
            <li>Wire Rope & Chain Slings</li>
            <li>Lifting Beams</li>
            <li>Special Hook Attachments</li>
            <li>Rollover & Leveling Devices</li>
            <li>Load Positioners</li>
            <li>Below the Hook Devices</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={7}>
      <ul>
            <li>Application Assistance</li>
            <li>Price Estimates</li>
            <li>Layout Development</li>
            <li>Engineering</li>
            <li>Systems Integration</li>
            <li>Professional Installations</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={8}>
      <ul>
            <li>Complete Control Panels</li>
            <li>Variable Frequency Controls</li>
            <li>Radio Controls</li>
            <li>Pushbutton Stations</li>
            <li>Control Upgrades</li>
            <li>Installation & Wiring</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={9}>
      <ul>
            <li>Replacement Parts</li>
            <li>Crane & Hoist Repairs</li>
            <li>Preventative Maintenance</li>
            <li>OSHA Inspections</li>
            <li>Service Agreements</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={10}>
      <ul>
            <li>Crane Kits</li>
            <li>End Truck & Drives</li>
            <li>Hoists</li>
            <li>Replacement Brakes</li>
        </ul>
      </TabPanel>
    </Box>
    </div>
    <div className='mobile-tabs'>
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 300, flexDirection: 'column' }}
    >
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label="Conveyors" {...a11yProps(0)} />
        <Tab label="Material Handling Equipment" {...a11yProps(1)} />
        <Tab label="Cranes" {...a11yProps(2)} />
        <Tab label="Hoists" {...a11yProps(3)} />
        <Tab label="Runways" {...a11yProps(4)} />
        <Tab label="Electrification" {...a11yProps(5)} />
        <Tab label="Accessories" {...a11yProps(6)} />
        <Tab label="Technical Assistance" {...a11yProps(7)} />
        <Tab label="Controls" {...a11yProps(8)} />
        <Tab label="Parts - Service Inspection" {...a11yProps(9)} />
        <Tab label="Components" {...a11yProps(10)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ul>
            <li>Accumulating</li>
            <li>Hinged Belt</li>
            <li>Screw</li>
            <li>Belt</li>
            <li>Line Shaft</li>
            <li>Special Application</li>
            <li>Chain Type</li>
            <li>Overhead Chain</li>
            <li>Vertical Reciprocating</li>
            <li>Flex Link</li>
            <li>Roller</li>
            <li>Vibratory</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ul>
            <li>Boom Attachments</li>
            <li>Box Dumpers</li>
            <li>Cabinets</li>
            <li>Carts</li>
            <li>Dock Plates</li>
            <li>Drum Dumpers</li>
            <li>Drum Equipment</li>
            <li>Fork Lift Attachments</li>
            <li>Hand Trucks</li>
            <li>Hoppers</li>
            <li>In-Plant Offices</li>
            <li>Lift Tables</li>
            <li>Lifts</li>
            <li>Mezzanines</li>
            <li>Pallet Equipment</li>
            <li>Partitions</li>
            <li>Racks</li>
            <li>Tilt Tables</li>
            <li>Tool Balancers</li>
            <li>Trolleys</li>
            <li>Vertical Reciprocating Conveyors</li>
            <li>Work Positioners</li>
            <li>Work Station Systems</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ul>
            <li>Top Running Cranes</li>
            <li>Under Running Cranes</li>
            <li>Trambeam Crane Systems</li>
            <li>Gantry Cranes - Jibs</li>
            <li>Monorails</li>
            <li>Gorbel Work Station Cranes</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <ul>
            <li>Most Capabilities & Applications</li>
            <li>Electric Wire Rope & Chain</li>
            <li>Air Wire Rope & Chain</li>
            <li>Manual Change</li>
            <li>Built-Up Hoists</li>
            <li>Special Use Hoist</li>
            <li>Winches</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ul>
            <li>Top and Under-Running Crane Runway Systems</li>
            <li>Patented Track System</li>
            <li>ASCE Rail Systems</li>
            <li>Runway Support Structures</li>
            <li>Structural Design & Fabrication</li>
            <li>Surveys & Inspection</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={5}>
      <ul>
            <li>Runway Electrification</li>
            <li>Conductor Bar Systems</li>
            <li>Flat Cable Festooning</li>
            <li>Electric Cable Reels</li>
            <li>Collectors</li>
            <li>Parts & Accessories</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={6}>
      <ul>
            <li>Nylon Strings</li>
            <li>Wire Rope & Chain Slings</li>
            <li>Lifting Beams</li>
            <li>Special Hook Attachments</li>
            <li>Rollover & Leveling Devices</li>
            <li>Load Positioners</li>
            <li>Below the Hook Devices</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={7}>
      <ul>
            <li>Application Assistance</li>
            <li>Price Estimates</li>
            <li>Layout Development</li>
            <li>Engineering</li>
            <li>Systems Integration</li>
            <li>Professional Installations</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={8}>
      <ul>
            <li>Complete Control Panels</li>
            <li>Variable Frequency Controls</li>
            <li>Radio Controls</li>
            <li>Pushbutton Stations</li>
            <li>Control Upgrades</li>
            <li>Installation & Wiring</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={9}>
      <ul>
            <li>Replacement Parts</li>
            <li>Crane & Hoist Repairs</li>
            <li>Preventative Maintenance</li>
            <li>OSHA Inspections</li>
            <li>Service Agreements</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={10}>
      <ul>
            <li>Crane Kits</li>
            <li>End Truck & Drives</li>
            <li>Hoists</li>
            <li>Replacement Brakes</li>
        </ul>
      </TabPanel>
    </Box>
    </div>
    <blockquote>SALES • INSTALLATION • PARTS • SERVICE • INSPECTION</blockquote>
    <Footer />
    </>;
    }