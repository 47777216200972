import React from 'react';

function BlogCard({title, content, author, id}) {
    console.log(title)
    return(
        <a className='blog-link' href={`/blog/${id}`}><div style={{backgroundImage: `url(../${id}.jpg)`}} className='blog-card'>
            <div style={{padding: '20px', backgroundColor:'rgba(255,255,255,0.79)'}}>
                <h2 className='blog-title'>{title}</h2>
                {/* <img src={`/${id}.jpg`} /> */}
                <p className='blog-author'>Author: {author}</p><br />
                <p className='blog-excerpt' dangerouslySetInnerHTML={{__html:content.split('</h1>')[1].slice(0,249)+'...'}}></p>
                <br/>
                <center><p style={{borderRadius: '10px', backgroundColor: 'black', color: 'white', minHeight: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Read More</p></center>
            </div>
        </div></a>
    );
}

export default BlogCard