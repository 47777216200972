import BlogPost from './blogPost'
import React from 'react'
import {useParams} from 'react-router-dom'

function BlogPage() {
    let {blogname} = useParams()
    // console.log(blogname)
    return (
        <BlogPost id={`${blogname}`} />
    )

}

export default BlogPage;