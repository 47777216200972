// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app"
import 'firebase/compat/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyCSikbph2EbzUJrbY138A0qufoFtAi3-2g",
  authDomain: "hoist-equipment-co.firebaseapp.com",
  projectId: "hoist-equipment-co",
  storageBucket: "hoist-equipment-co.appspot.com",
  messagingSenderId: "122285404048",
  appId: "1:122285404048:web:464cd1e46ac32495ec4d15",
  measurementId: "G-E12K50PZG0"
};

firebase.initializeApp(firebaseConfig)

export default firebase;