import React, { useState, useEffect } from 'react';
import { Button } from './Button3';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [mobbutton, setMobButton] = useState(false);
  const [logo, setLogo] = useState(true);
  const [moblogo, setmobLogo] = useState(true);
  const [navInfo, setNavInfo] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const showMobButton = () => {
    if (window.innerWidth <= 960) {
      setMobButton(true);
    } else {
      setMobButton(false)
    }
  }

  const showLogo = () => {
    if (window.innerWidth <= 960) {
      setLogo(false);
    } else {
      setLogo(true);
    }
  };

  const showMobLogo = () => {
    if (window.innerWidth >= 960) {
      setmobLogo(false);
    } else {
      setmobLogo(true);
    }
  };

  const showNavInfo = () => {
    if (window.innerWidth <= 960) {
      setNavInfo(false);
    } else {
      setNavInfo(true);
    }
  };

  useEffect(() => {
    showMobButton();
  }, []);

  useEffect(() => {
    showLogo();
  }, []);

  useEffect(() => {
    showButton();
  }, []);

  useEffect(() => {
    showMobLogo();
  }, []);

  useEffect(() => {
    showNavInfo();
  }, []);

  window.addEventListener('resize', showButton);
  window.addEventListener('resize', showLogo);
  window.addEventListener('resize', showMobLogo);
  window.addEventListener('resize', showNavInfo);
  window.addEventListener('resize', showMobButton);
  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            {logo && <img alt="Hoist Equipment Company Logo" src="/images/hoist-logo-desktop.jpg"/>}
            {moblogo && <img alt="Hoist Equipment Company Logo" className='mobLogo' src="/images/hoist-logo-mobile.jpg"/>}
          </Link>
          {navInfo && <>
            <div className='busInfo'>
              <i className="fas fa-home"></i>
              26161 Cannon Road <br />
              Bedford Heights, OH 44146
            </div>
            <div className='busInfo'>
              <i className="fas fa-envelope"></i>
              Send Us an Email: <br />
              sales@hoistequipment.com
            </div>
            </>
          }
          {button && <Button className='btn2--outline' buttonStyle='btn2--outline'>CONTACT US<br/>(440) 232-0300</Button>}
        </div>
        <div className='navbar-subcontainer'>
          <div className='menu-icon' onClick={handleClick}>
              <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              <li className='nav-item'>
                <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                  Home
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to='/services'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  Services
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to='/about-hoist'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  About Hoist
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to='/blog'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  Blog
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to='/rfq'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  Get a Quote
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to='/employment'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  Employment
                </Link>
              </li>
              <li>
                <Link
                  to='/contact'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  Contact Us
                </Link>
              </li>
              <li>
              {mobbutton && <Button className='btn2--outline' buttonStyle='btn2--outline'>CONTACT US<br/>(440) 232-0300</Button>}
              </li>
            </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
