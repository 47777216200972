import React from 'react';
import '../../App.css';
import QuoteForm from '../../components/QuoteForm.js'
import Footer from '../../components/Footer.js'

export default function ContactUs() {
return <>
{/* <center><h1 style={{marginTop: 75 + 'px'}}>Request for Quotation</h1></center> */}
<div className="text-area">
    <div style={{backgroundColor:'rgba(0,0,0,.75)!important'}}className="text-content rfq">
        <QuoteForm />
    </div>
</div>
<Footer />
</>;
}