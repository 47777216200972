import React from 'react';
import {useState, useEffect} from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Linecard from './components/pages/linecard';
import Products from './components/pages/Products';
import SignUp from './components/pages/SignUp';
import aboutHoist from './components/pages/aboutHoist';
import contact from './components/pages/contact';
import rfq from './components/pages/rfq';
import BlogPage from './components/pages/blogPage';
import Blog from './components/pages/blog';
import Category from './components/pages/category';
import EmploymentForm from './components/pages/employment'
import ScrollToTop from './ScrollToTop';
// import firebase from './Firebase'

function App() {

  return (
    <>
      <Router>
        <Navbar />
        <ScrollToTop />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/services' component={Linecard} />
          <Route path='/products' component={Products} />
          <Route path='/sign-up' component={SignUp} />
          <Route path='/about-hoist' component={aboutHoist} />
          <Route path='/contact' component={contact} />
          <Route path='/blog/:blogname' component={BlogPage} />
          <Route path='/category/:category' component={Category} />
          <Route path='/blog' component={Blog} />
          <Route path='/rfq' component={rfq} />
          <Route path='/employment' component={EmploymentForm} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
