import React from 'react';
import '../../App.css';
import Timeline from '../../components/timeline';
import { StyleRoot } from 'radium';
import Footer from '../../components/Footer'
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';

const images = [
  {
    original: 'images/gallery/1.jpg',
    thumbnail: 'images/gallery/1_tn.jpg',
    description: 'Horizonal belt conveyor.',
    fullscreen:'images/gallery/1_original.jpg'
  },
  {
    original: 'images/gallery/2.jpg',
    thumbnail: 'images/gallery/2_tn.jpg',
    description: 'Top running crane systems.',
    fullscreen:'images/gallery/2_original.jpg'
  },
  {
    original: 'images/gallery/3.jpg',
    thumbnail: 'images/gallery/3_tn.jpg',
    description: 'Floor-supported crane system with multiple cranes.',
    fullscreen:'images/gallery/3_original.jpg'
  },
  {
    original: 'images/gallery/4.jpg',
    thumbnail: 'images/gallery/4_tn.jpg',
    description: 'Automated machine tool loading system.',
    fullscreen:'images/gallery/4_original.jpg'
  },
  {
    original: 'images/gallery/5.jpg',
    thumbnail: 'images/gallery/5_tn.jpg',
    description: 'Flux recovery conveyor.',
    fullscreen:'images/gallery/5_original.jpg'
  },
  {
    original: 'images/gallery/6.jpg',
    thumbnail: 'images/gallery/6_tn.jpg',
    description: 'Custom installation of top running crane system.',
    fullscreen:'images/gallery/6_original.jpg'
  },
  {
    original: 'images/gallery/7.jpg',
    thumbnail: 'images/gallery/7_tn.jpg',
    description: 'Special automation.',
    fullscreen:'images/gallery/7_original.jpg'
  },
  {
    original: 'images/gallery/8.jpg',
    thumbnail: 'images/gallery/8_tn.jpg',
    description: 'Assembly conveyor for truck bodies.',
    fullscreen:'images/gallery/8_original.jpg'
  },
  {
    original: 'images/gallery/9.jpg',
    thumbnail: 'images/gallery/9_tn.jpg',
    description: 'Vacuum Crushing System for Extracting Gasses.',
    fullscreen:'images/gallery/9_original.jpg'
  },
  {
    original: 'images/gallery/10.jpg',
    thumbnail: 'images/gallery/10_tn.jpg',
    description: 'Top running crane.',
    fullscreen:'images/gallery/10_original.jpg'
  },
  {
    original: 'images/gallery/11.jpg',
    thumbnail: 'images/gallery/11_tn.jpg',
    description: 'Vacuum Grab for removing Cured Parts from Mold.',
    fullscreen:'images/gallery/11_original.jpg'
  },
  {
    original: 'images/gallery/12.jpg',
    thumbnail: 'images/gallery/12_tn.jpg',
    description: 'Special Winch.',
    fullscreen:'images/gallery/12_original.jpg'
  },
  {
    original: 'images/gallery/13.jpg',
    thumbnail: 'images/gallery/13_tn.jpg',
    description: 'Traveling wall cranes under overhead cranes.',
    fullscreen:'images/gallery/13_original.jpg'
  },
  {
    original: 'images/gallery/14.jpg',
    thumbnail: 'images/gallery/14_tn.jpg',
    description: 'Weigh-scale conveyor for chemical batching system.',
    fullscreen:'images/gallery/14_original.jpg'
  },
  {
    original: 'images/gallery/15.jpg',
    thumbnail: 'images/gallery/15_tn.jpg',
    description: 'Mold handling system. Max capacity 50 ton.',
    fullscreen:'images/gallery/15_original.jpg'
  },
  {
    original: 'images/gallery/16.jpg',
    thumbnail: 'images/gallery/16_tn.jpg',
    description: 'Automated monorail system delivering product to shipping department.',
    fullscreen:'images/gallery/16_original.jpg'
  },
  {
    original: 'images/gallery/17.jpg',
    thumbnail: 'images/gallery/17_tn.jpg',
    description: 'Tote dumper for chemical batching system.',
    fullscreen:'images/gallery/17_original.jpg'
  },
  {
    original: 'images/gallery/18.jpg',
    thumbnail: 'images/gallery/18_tn.jpg',
    description: 'Top running crane.',
    fullscreen:'images/gallery/18_original.jpg'
  }
];


export default function SignUp() {
  return <>
  <div className="page-content">
    <h1 className="page-header">About Hoist Equipment Company, Inc.</h1>
    <div className="text-area">
      <h2>One Source. One Responsibility.</h2>
      <div className="text-content">
        <p className="text-content-entry">
        Since our beginning in 1954, we have specialized in overhead crane systems, monorails, hoists, conveyors, and many types of standard and custom material handling equipment.
        <br />
        <br />
        Our involvement in this field virtually covers all product applications, sales, engineering, fabrication, installation, parts, service, and inspections. We believe that our "one source, one responsibility" concept allows the customer to bring together all of the important aspects of the project with one qualified source, Hoist Equipment Company.
        <br />
        <br />
        With 40,000 square feet of manufacturing space, staffed with experienced technical personnel, full engineering capabilities, and a professional installation crew. We are equipped to get the job done. Whether the requirement is a complete new crane system, modernization or service work, we make every effort to recommend the best, most cost effective solution. By combining quality and value in a timely manner, with sensitivity to our customers needs, the successful conclusion of each project is assured.
        </p>
        <div className="gallery">
          <h2>Some of Our Work</h2>
          <ImageGallery items={images} lazyLoad='true' autoPlay='true'/>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div className="bottomContainer">
    <div className="container--left">
      <h2>A Brief History of Hoist</h2>
      <StyleRoot>
        <Timeline>
          <div>
            <div className="text-content-timeline">
              <h3>1954</h3>
              <h4>Humble Beginnings.</h4>
              <p>Hoist Equipment Company, Inc. is Founded</p>
            </div>
          </div>
          <div>
            <div className="text-content-timeline">
              <h3>1967</h3>
              <h4>Expansion to Pennsylvania</h4>
              <p>Hoist establishes Pittsburgh Crane and Conveyor Company</p>
            </div>
          </div>
          <div>
            <div className="text-content-timeline">
              <h3>2004</h3>
              <h4>50 Years of Service</h4>
              <p>In 2004, Hoist Equipment Company, Inc. celebrated its' 50th year in business.</p>
            </div>
          </div>
          <div>
            <div className="text-content-timeline">
              <h3>2021</h3>
              <h4>To the Future</h4>
              <p>With nearly 70 years of creating custom solutions for our customers, Hoist is still working day and night to ensure their customers' successes. Call us today to see how we can help you!</p>
            </div>
          </div>
        </Timeline>
      </StyleRoot>
      <br />
      <br />
    </div>
    <div className="container--right">
      <img alt='Caterpillar Loader' src="images/caterpillar-loader.jpg" width="95%" height="95%" />
    </div>
  </div>
  <Footer />
  </>;
}
