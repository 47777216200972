import React, { useState } from "react";

const QuoteForm = () => {
  const [status, setStatus] = useState("Submit");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { requested_service, urgency, due, name, company, email, number, address, description } = e.target.elements;
    let details = {
        requested_service: requested_service.value,
        urgency: urgency.value,
        due: due.value,
        name: name.value,
        company: company.value,
        email: email.value,
        number: number.value,
        address: address.value,
        description: description.value,
    };
    let response = await fetch("http://localhost:5000/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    let result = await response.json();
    alert(result.status);
  };
  return (

    <iframe
      id="JotFormIFrame-222736690397063"
      title="Request for Quotation"
      onload="window.parent.scrollTo(0,0)"
      allowtransparency="true"
      allowfullscreen="true"
      allow="geolocation; microphone; camera"
      src="https://form.jotform.com/222736690397063"
      frameborder="0"
      style={{
      minWidth: '100%',
      height:'539px',
      border:'none', backgroundColor:'rgba(0,0,0,.75)'}}
      scrolling="no"
    ></iframe>
  );
}
export default QuoteForm;