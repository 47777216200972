import React from 'react';
import '../../App.css';
import ContactForm from '../../components/Form.js'
import Footer from '../../components/Footer.js'

export default function ContactUs() {
return <>
<center><h1 style={{marginTop: 75 + 'px'}}>Contact Us Now</h1></center>
<div className="text-area">
    <div className="text-content text-content-entry">
        <ContactForm />
        <p>Please allow us 24 hours to get back to you. We promise, we'll get in touch with you as soon as possible!</p>
    </div>
    <div className="contact-heading text-content text-content-entry">
        <h2>Use this Form to Contact Us Now.</h2>
        <p>Thank you for visiting us! For any information, please reach out to us by phone, or with our contact form. We look forward to hearing from you!</p>
        <p>This form is for General Inquiries, if you're looking for a quote, use this <a href="#">Quote Form</a> instead for faster service!</p>
        <div className='hoistHours'>
            <h3>Hours of Operation</h3>
            <table>
                <thead>
                    <tr><td>Day</td>
                        <td>Hours</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Monday</td>
                        <td>9:00AM - 5:00PM EST</td>
                    </tr>
                    <tr>
                        <td>Tuesday</td>
                        <td>9:00AM - 5:00PM EST</td>
                    </tr>
                    <tr>
                        <td>Wednesday</td>
                        <td>9:00AM - 5:00PM EST</td>
                    </tr>
                    <tr>
                        <td>Thursday</td>
                        <td>9:00AM - 5:00PM EST</td>
                    </tr>
                    <tr>
                        <td>Friday</td>
                        <td>9:00AM - 5:00PM EST</td>
                    </tr>
                    <tr>
                        <td>Saturday</td>
                        <td>CLOSED</td>
                    </tr>
                    <tr>
                        <td>Sunday</td>
                        <td>CLOSED</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div>
<iframe className="google-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2992.343079246694!2d-81.49626654891571!3d41.410070679160214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88311d74b5ad02a7%3A0xe2d766173dc65f2d!2sHoist%20Equipment%20Co%20Inc!5e0!3m2!1sen!2sus!4v1639261271079!5m2!1sen!2sus" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
</div>
<Footer />
</>;
}