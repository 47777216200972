import React from 'react';
import '../App.css';
import { Button } from './Button';
import { Button2 } from './Button2';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      <h1>Hoist Equipment Co.</h1>
      <p>Anyone can sell you a lift, hoist, or conveyor. But who do you turn to when your project calls for more than an out of the box solution?
        <br /><br />
        Hoist Equipment Company is the only choice for customized material handling solutions that bridge the gap from what is in the catalog, to what you actually need to get the job done.
      </p>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
        >
          Request a Quote
        </Button>
        <Button2
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          onClick={console.log('hey')}
        >
          Contact Hoist <i className='far fa-address-card' />
        </Button2>
      </div>
    </div>
  );
}

export default HeroSection;
